<template>
    <div class="row welcome my-3 justify-content-center">
      <div class="col-12 col-lg-10 row m-0 p-0">
        <div class="col-12">
          <div class="h4 text-muted">
            Unser Ziel seit <span class="d-none d-sm-inline">über</span> 20 Jahren:
          </div>
          <div class="h1 company-color1">Dich sicher auf die Straße bringen.</div>
        </div>
        <div class="col-12 col-lg-9 col-xl-10">
          <p>Seit über 20 Jahren erfüllen wir vielen Menschen den Traum vom eigenen Führerschein.</p>
          <p>
            Durch eine individuelle Betreuung und regelmäßige Theorie- und Praxisstunden machen wir dich zu einem sicheren Fahrer.
            <br />
            <template v-if="branchcount!=0">Besuche uns doch in <template v-if="branchcount==1">unserer Filiale</template><template v-if="branchcount==2">einer unserer zwei Filialen</template><template v-if="branchcount==3">einer unserer drei Filialen</template><template v-if="branchcount>=4">einer unserer Filialen</template>.</template>
          </p>
          <p>Auch nach deiner Ausbildung stehen wir dir gerne für Fragen zur Verfügung.</p>
        </div>
        <!-- AZAV-Logo DESTOP -->
        <div class="col-3 col-xl-2 d-none d-lg-block">
          <img class="w-100 pb-2" src="@/assets/pictures/azav-traegerzulassung.png" />
        </div>
        <div class="col-12">
          <p>
            Übrigens: Unsere Fahrschule ist ein nach AZAV zertifizierter Bildungsträger.
            <br />Das bedeutet für dich, dass wir nach festgelegten Maßstäben ausbilden und du unter bestimmten Vorraussetzungen deine Ausbildung bei uns vom Arbeitsamt fördern lassen kannst.
          </p>
          <small class="text-muted">- Dein Team der Fahrschule Werner</small>
        </div>
      </div>
      <!-- AZAV-Logo Mobile -->
      <div class="col-5 col-sm-3 col-md-2 d-block d-lg-none">
        <img class="w-100" src="@/assets/pictures/azav-traegerzulassung.png" />
      </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: "welcome",
  computed: {
    ...mapGetters({
        branchcount: 'branches_count'
    })
  }, 
  created() {
    this.$store.dispatch("updateBranches")
  }
};
</script>

<style>
</style>

<template>
  <div class="nav">
    <b-container fluid class="header-main header-caption">
      <b-row class="d-flex d-lg-none" align-h="between">
        <b-col cols="auto" class="p-3" @click="togglemobileNav()">
          <span class="fa fa-bars"></span>
        </b-col>
        <b-col cols="auto" class="text-center p-3 m-0">
          <span class="header-branch">FAHRSCHULE WERNER</span>
        </b-col>
        <b-col cols="auto" class="header-search-profil text-right">
          <span class="fa fa-search d-none"></span>
          <div class="nav-user p-3">
            <span v-if="showlogin" class="nav-user-logo fa fa-user" onclick="togglenavlogin();"></span>
          </div>
          <div class="d-none">
            <span class="fas fa-globe d-none p-3 m-0"></span>
          </div>
        </b-col>
      </b-row>
      <b-row class="d-lg-flex d-none" align-h="between">
        <b-col cols="auto" class="p-2 m-0" @click="$router.push({path: '/'})" style="cursor: pointer;">
          <span class="header-branch text-left">FAHRSCHULE WERNER</span>
          <img class="tire" src="@/assets/pictures/lmedia/tire_track.png" />
        </b-col>
        <b-col cols="auto" class="p-2 m-0">
          <ul class="header-links">
            <li v-bind:class="nav_1" id="nav_1">
              <router-link to="/">Startseite</router-link>
            </li>
            <li v-bind:class="nav_2" id="nav_2" class="ml-1">
              <router-link to="/theorie">Theoriezeiten</router-link>
            </li>
            <li v-bind:class="nav_3" id="nav_3" class="ml-1">
              <router-link to="/massnahmen">Maßnahmen</router-link>
            </li>
            <li v-bind:class="nav_4" id="nav_3" class="ml-1">
              <router-link to="/klassen">Fahrzeugklassen</router-link>
            </li>
            <!-- <li v-bind:class="nav_5" id="nav_5" class="ml-1">
              <router-link to="/asffes">Aufbaukurs / Punkteabbau</router-link>
            </li> -->
            <li v-bind:class="nav_6" id="nav_6" class="ml-1 d-none d-xl-inline">
              <router-link to="/kontakt">Kontakt</router-link>
            </li>
          </ul>
        </b-col>
        <b-col cols="auto" class="header-search-profil p-2">
            <span  v-if="showlogin" class="nav-user-logo fa fa-user" onclick="togglenavlogin();"></span>
        </b-col>
      </b-row>
    </b-container>
    <!-- Mobile Nav Overlay -->
    <div v-show="showMobile" id="mobileNav" class="overlay d-lg-none" v-bind:height="heightMobile">
      <!-- Button to close the overlay navigation -->
      <a href="javascript:void(0)" class="closebtn" @click="togglemobileNav()">&times;</a>
      <div id="logo">
        <img src="@/assets/pictures/logo.png" />
      </div>
      <!-- Overlay content -->
      <div class="overlay-content">
        <hr class="mx-5 border-secondary" />

        <router-link v-on:click.native="togglemobileNav()" to="/">Startseite</router-link>
        <router-link v-on:click.native="togglemobileNav()" to="/theorie">Theoriezeiten</router-link>
        <router-link v-on:click.native="togglemobileNav()" to="/massnahmen">Maßnahmen</router-link>
        <router-link v-on:click.native="togglemobileNav()" to="/klassen">Fahrzeugklassen</router-link>
        <!-- <router-link v-on:click.native="togglemobileNav()" to="/asffes">Aufbaukurs / Punkteabbau</router-link> -->
        <router-link v-on:click.native="togglemobileNav()" to="/kontakt">Kontakt</router-link>
      </div>
    </div>
    <!-- Login Prompt -->
    <div class="nav-user-info">
      Login
      <br />
      <form
        id="header-login"
        v-if="user === null"
        action="<?php echo SITE_URL; ?>includes/process_login.php"
        method="post"
        name="login_form"
      >
        <div class="container">
          <div class="form-group">
            <label for="email">
              <b>Email:</b>
            </label>
            <input
              class="form-control"
              type="text"
              placeholder="Enter E-Mail"
              name="email"
              required
              autofocus
            />
          </div>
          <div class="form-group">
            <label for="password">
              <b>Passwort:</b>
            </label>
            <input
              class="form-control"
              type="password"
              placeholder="Enter Password"
              name="password"
              id="password"
              onkeypress="KeyCode;"
              required
            />
          </div>
          <input
            class="form-control"
            id="loginbutton"
            type="button"
            value="<?php echo $nav_login->logincaption; ?>"
            onclick="formhash(this.form, this.form.password);"
          />
          <label>
            <input type="checkbox" checked="checked" name="remember" /> Login
          </label>
        </div>

        <div class="container">
          <span class="reg">
            Noch keinen Account
            <router-link to="registrieren">Jetzt registrieren</router-link>
          </span>
          <span class="psw">
            <a href="#">Eingeloggt bleiben</a>
          </span>
        </div>
      </form>
      <div v-if="user">
        <p>Hallo {{ user.username }}!</p>
        <br />Admintools?
        <br />Kontoinformationen
        <br />
        <p>
          <router-link to="logout">Logout</router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Nav",
  data() {
    return {
      showMobile: false,
      heightMobile: 0,
      // Settings
      showlogin: false,
    }
  },
  computed: {
    ...mapGetters({
      user: "currentUser",
      company: "company_get"
    }),
    nav_1: function() {
      return this.nav("/");
    },
    nav_2: function() {
      return this.nav("/theorie");
    },
    nav_3: function() {
      return this.nav("/massnahmen");
    },
    nav_4: function() {
      return this.nav("/klassen");
    },
    nav_5: function() {
      return this.nav("/asffes");
    },
    nav_6: function() {
      return this.nav("/kontakt");
    }
  },
  methods: {
    nav(key) {
      return {
        "nav-active": key == this.$route.path,
        "nav-grey": key != this.$route.path
      };
    },
    togglemobileNav() {
      return (this.showMobile) ? this.showMobile=false : this.showMobile=true
    }
  }
};
</script>

<style scoped>
.header-main {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: #2e2e2e;
  color: white;
}

.header-branch {
  position: relative;
  background-image: linear-gradient(to right, #ff00ff, yellow);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  z-index: 10;
}
.tire {
  position: absolute;
  width: 8em;
  z-index: 5;
  top: -4em;
  left: 5em;
}
.header-links {
  position: relative;
  list-style-type: none;
  margin: 0;
  padding: 0;
  transform: skew(-10deg);
  z-index: 10;
}
.header-links li {
  position: relative;
  display: inline;
  padding: 1em;
}
.header-links li:hover,
.nav-active {
  background-color: yellow;
}
.nav-grey {
  background-color: grey;
}
.header-links li a {
  text-transform: uppercase;
  position: relative;
  color: white;
  text-decoration: none;
}
.header-links li:hover a,
.header-links li a:hover,
.nav-active a {
  color: black !important;
  text-decoration: none;
}
.gradient-purple-yellow {
  background: linear-gradient(to right, #ff00ff, yellow);
}
.nav-user-logo {
  cursor: pointer;
}
.nav-user-info {
  display: none;
  background-color: #2e2e2e;
  position: absolute;
  top: 2em;
  right: 0;
  width: 20em;
  padding: 1em;
  z-index: 1000;
  border-radius: 0.2em;
}
#mobileNav {
  /* Height & width depends on how you want to reveal the overlay (see JS below) */   
  width: 100%;
  height: 100vh;
	position: fixed; /* Stay in place */
	z-index: 1000; /* Sit on top */
	left: 0;
	top: 0;
	background-color: rgb(0,0,0); /* Black fallback color */
	background-color: rgba(0,0,0, 0.9); /* Black w/opacity */
	overflow-x: hidden; /* Disable horizontal scroll */
	transition: 0.5s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
	}

	/* Position the content inside the overlay */
	#mobileNav .overlay-content {
	position: relative;
	top: 25vh; /* 25% from the top */
  width: 100%; /* 100% width */
  text-align: center; /* Centered text/links */
  z-index: 1;
	}

	/* The navigation links inside the overlay */
	#mobileNav a {
	padding: 8px;
	text-decoration: none;
	font-size: 25px;
	color: #818181;
	display: block; /* Display block instead of inline */
	transition: 0.3s; /* Transition effects on hover (color) */
	}

	/* When you mouse over the navigation links, change their color */
	#mobileNav a:hover, #mobileNav a:focus {
	color: #f1f1f1;
	}

	#mobileNav #logo{
	position: absolute;
	top: 4vh;
  height: 20vh;
  max-height: 12rem;
	width: 100%;
	text-align: center;
	}
	#mobileNav img {
    height: 100%;
    max-width: 20rem;
		opacity: 0.9;
	}

	/* Position the close button (top right corner) */
	#mobileNav .closebtn{
	position: absolute;
	top: 0;
	right: 5%;
	font-size: 60px;
	z-index: 1;
	}

	/* When the height of the screen is less than 450 pixels, change the font-size of the links and position the close button again, so they don't overlap */
	@media screen and (max-height: 450px) {
	#mobileNav a {font-size: 20px}
	#mobileNav .closebtn {
		font-size: 40px;
		top: 15px;
		right: 35px;
	}
	} 
</style>

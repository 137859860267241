import Vue from 'vue'
import API from '@/plugins/api.js'
Vue.use(API)
let store = new Vue() // use the store var for request api
const module_branch = {
  state: { 
    loaded: null,
    branches: JSON.parse(localStorage.getItem('branches')) || null
  },
  mutations: {
    branches_set(state, branches) {
      state.branches=branches;
      localStorage.setItem('branches', JSON.stringify(branches))
      state.loaded=true;
    },
    branches_startup(state) {
      state.loaded=false;
    }
  },
  actions: {
    async initBranches(context) {
      if (context.state.loaded!==null) return;
      context.commit('branches_startup');
      setInterval(() => context.dispatch('updateBranches'), 5 * 60 * 1000);
      let offset = 0;
      let code = async () => {
        if (offset < 10) offset++;
        await context.dispatch('updateBranches')
          .catch(err => {
            setTimeout(() => code(), 1000 + offset * 1000);
            if (!err.includes("Network")) throw err;
            else console.warn(err);
          });
      }
      await code();
    },
    async updateBranches(context) {
        return await store.$api.get("company/branch")
          .then(response => {
            context.commit("branches_set", response.data.result)
            return response;
          })
    },
  },
  getters: {
    branches(state) {
      return state.branches
    },
    module_branch_loaded(state) {
      return state.branches && state.branches!=null
    },
    branches_count(state) {
      return state.branches.length
    }
   }
}
export default module_branch
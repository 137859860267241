// The Cookie Consent Settings

import "cookieconsent"
window.cookieconsent.initialise({
    palette: {
        popup: {
            background: "#000"
            },
        button: {
            background: "transparent",
            text: "#f1d600",
            border: "#f1d600"
            }
        },
    type: "opt-in",
    content: {
        message: "Diese Website verwendet Cookies, um die Nutzerfreundlichkeit zu verbessern. Weitere Infos zu Cookies finden Sie ",
        dismiss: "Schließen",
        allow: "Annehmen",
        deny: "Ablehnen",
        link: "hier",
        href: "https://www.cookiesandyou.com/",
        policy: "Cookies"
        }
    })
<template>
  <div class="container-fluid" id="classes-banner">
    <div class="row my-3 justify-content-center banner mx-3" @click="$router.push('job')" style="cursor: pointer;">
      <div class="col-12 col-lg-10 text-center font-weight-bold mt-2">
        <h3>!! WIR SUCHEN DICH !!</h3>
      </div>
      <div class="col-12 col-lg-10 mt-3 p-0 text-center font-weight-bold">
            Wir suchen ab sofort dringend Verstärkung für unser Team. Egal ob mit jahrelanger Erfahrung oder Quereinsteiger, bei uns bist du herzlich willkommen.
      </div>
      <div class="col-12 col-lg-10 mt-3 p-0 text-center underline-object-on-hover">
           <em>Klicke hier für weitere Informationen</em>
      </div>
      <div class="col-12 col-lg-10 my-3 p-0 text-center d-none d-lg-block">
            unbefristeter Arbeitsvertrag | 
            faire, branchenüberdurchschnittliche Bezahlung | 
            strukturierte und familiäre Arbeitsumgebung
      </div>
      <div class="col-12 col-lg-10 my-3 p-0 text-center d-block d-lg-none">
            unbefristeter Arbeitsvertrag <br />
            faire, branchenüberdurchschnittliche Bezahlung <br />
            strukturierte und familiäre Arbeitsumgebung
      </div>
    </div>
  </div>
</template>

<script>
export default {
    
}
</script>
<style scoped>
    .banner {
        border-style: double;
        border-color: #ff00ff;
    }
    .underline-object-on-hover:hover {
        text-decoration: underline;
    }
</style>
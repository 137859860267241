<template>
  <div class="home container-fluid">
    <!-- SLIDER -->
    <div class="row">
      <div class="col-12 p-0">
        <Slider />
      </div>
    </div>
    <!-- ANNOUNCEMENT -->
    <div class="row justify-content-center">
      <div class="col-xs-12 col-lg-10 mt-3">
        <fa-element
          client_id="fahrschule_werner"
          type="announcement"
          announcement_type="index"
          title="Ankündigungen"
          hideEmpty
          hideLoader
        />
      </div>
    </div>
    <Welcome />
    <Job />
    <Vision />
    <Guideline />
    <div class="row" @click="handleOpenReviews" style="cursor: pointer">
      <div class="col-6 text-right">
        <img src="@/../public/Google.png" height="40rem" />
      </div>
      <div class="col-6 my-auto">Bewerte uns auf Google</div>
    </div>
    <!-- <Review /> -->
    <Branch />
    <!-- NEWSLETTER -->
    <div class="row py-3">
      <div class="col-12 text-center">
        <h5>Trage dich bei unserem Newsletter ein</h5>
        <fa-element
          client_id="fahrschule_werner"
          type="mail_list"
          id="1"
          center
        />
      </div>
    </div>
  </div>
</template>

<script>
import Branch from "@/components/shared/Branches";
import Guideline from "@/components/layout/index/Guideline";
import Slider from "@/components/layout/index/Slider";
import Job from "@/components/layout/index/job/Banner";
// import Review from "@/components/layout/index/Review"
import Vision from "@/components/layout/index/Vision";
import Welcome from "@/components/layout/index/Welcome";
export default {
  name: "Home",
  data() {
    return {
      announcementtype: "index",
      announcementfound: false,
    };
  },
  components: {
    Branch,
    Guideline,
    Slider,
    // Review,
    Job,
    Vision,
    Welcome,
  },
  methods: {
    handleOpenReviews() {
      window.open(
        "https://www.google.de/maps/place/Fahrschule+Werner/@51.3399012,12.2712662,12z/data=!4m8!1m2!2m1!1sFahrschule+Werner!3m4!1s0x47a6f79fd16dfc2f:0xb5a5ec2f732f4dd4!8m2!3d51.3105893!4d12.2761765"
      );
    },
  },
};
</script>

<template>
    <carousel
      class="slider m-0 p-0"
      loop
      autoplay
      :autoplayTimeout="5000"
      paginationPosition="bottom-overlay"
      paginationActiveColor="#efefef"
      paginationColor="#000000"
      :per-page="1" 
    >
      <slide>
        <div
          class="container-fluid"
          style="height: 300px; background-color: #2e2e2e; background-position: center; background-size: cover;"
          :style="{'background-image': 'url(\''+image1+'\')'}"
        >
          <div class="row p-5 justify-content-center" >
            <div class="col-10 text-white font-weight-bold text-center" style="background-color: rgba(0,0,0,0.5); font-size: 20px;">
              Herzlich Willkommen bei der Fahrschule Werner
              <br>
              <img src="@/assets/pictures/logo.png" alt="Logo" style="width: 30vh; position: relative; padding: 10px;"/>
            </div>
          </div>
        </div>
      </slide>
      <slide>
          <div
          class="container-fluid"
          style="height: 300px; background-color: #2e2e2e; background-position: center; background-size: cover;"
          :style="{'background-image': 'url(\''+image2+'\')'}"
        >
          <div class="row p-5 justify-content-center" >
            <div class="col-auto text-light font-weight-bold text-center mt-5 p-3" style="font-size: 20px; background-color: rgba(0,0,0,0.5);">
              Betreuung und Unterricht in 2 Filialen
              <br>
              <small>Damit du noch schneller zu deinem Führerschein kommst.</small>
            </div>
          </div>
        </div>
      </slide>
    </carousel>
</template>

<script>
import Image from "@/assets/pictures/max_werner/WhatsApp Image 2019-12-10 at 15.58.59-edited.jpeg";
import Image2 from "@/assets/pictures/paul_werner/2branches.png";
import Image3 from "@/assets/pictures/paul_werner/Covid19.jpeg";
import { Carousel, Slide } from "vue-carousel"; // https://www.npmjs.com/package/vue-carousel#usage
export default {
  name: "slider",
  components: {
    Carousel,
    Slide
  },
  data() {
    return {
      slide: 0,
      sliding: false,
      image1: Image,
      image2: Image2,
      image3: Image3
    };
  },
  methods: {
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    }
  }
};
</script>

<style>
#indexCarousel {
  z-index: 0;
}
</style>
<template>
  <div class="branches">
  <!-- <div v-if="loaded" class="branches"> -->
    <div class="container-fluid mt-5">
      <h3 class="text-center">Unsere Filialen</h3>
      <div class="row justify-content-center" v-if="branches && branches.length" >
        <div v-for="branch in branches" :key="branch.id" class="card m-3 h-100" style="width: 18rem;">
          <div class="card-body text-left">
            <h5 class="card-title">Filiale: {{ branch.name }}</h5>
            <p class="card-text">
              {{ branch.address.street }} {{ branch.address.streetnumber }}
              <br />
              {{ branch.address.zip }} {{ branch.address.city }}
              <br />
              <span class="font-weight-bold">Tel.: </span><template v-if="branch.phone_numbers.find(el => el.role == 'primary')">{{ branch.phone_numbers.find(el => el.role == 'primary').value }}</template>
            </p>
            <hr class="mx-2" />Öffnungszeiten:
            <div>
              <div class="d-flex justify-content-between">Mo: 
                <div v-if="branch.openHours.find(el => el.open_day=='MONDAY')" class="text-secondary">
                  <div v-for="openHour in branch.openHours.filter(el => el.open_day=='MONDAY')" :key="JSON.stringify(openHour)">
                    {{ openHour.open_time.substr(0,5) }} - {{ openHour.close_time.substr(0,5) }}
                  </div>
                </div>
                <div v-else class="text-secondary">geschlossen</div>
              </div>
              <div class="d-flex justify-content-between">Di: 
                <div v-if="branch.openHours.find(el => el.open_day=='TUESDAY')" class="text-secondary">
                  <div v-for="openHour in branch.openHours.filter(el => el.open_day=='TUESDAY')" :key="JSON.stringify(openHour)">
                    {{ openHour.open_time.substr(0,5) }} - {{ openHour.close_time.substr(0,5) }}
                  </div>
                </div>
                <div v-else class="text-secondary">geschlossen</div>
              </div>
              <div class="d-flex justify-content-between">Mi: 
                <div v-if="branch.openHours.find(el => el.open_day=='WEDNESDAY')" class="text-secondary">
                  <div v-for="openHour in branch.openHours.filter(el => el.open_day=='WEDNESDAY')" :key="JSON.stringify(openHour)">
                    {{ openHour.open_time.substr(0,5) }} - {{ openHour.close_time.substr(0,5) }}
                  </div>
                </div>
                <div v-else class="text-secondary">geschlossen</div>
              </div>
              <div class="d-flex justify-content-between">Do: 
                <div v-if="branch.openHours.find(el => el.open_day=='THURSDAY')" class="text-secondary">
                  <div v-for="openHour in branch.openHours.filter(el => el.open_day=='THURSDAY')" :key="JSON.stringify(openHour)">
                    {{ openHour.open_time.substr(0,5) }} - {{ openHour.close_time.substr(0,5) }}
                  </div>
                </div>
                <div v-else class="text-secondary">geschlossen</div>
              </div>
            </div>
              <div class="d-flex justify-content-between">Fr: 
                <div v-if="branch.openHours.find(el => el.open_day=='FRIDAY')" class="text-secondary">
                  <div v-for="openHour in branch.openHours.filter(el => el.open_day=='FRIDAY')" :key="JSON.stringify(openHour)">
                    {{ openHour.open_time.substr(0,5) }} - {{ openHour.close_time.substr(0,5) }}
                  </div>
                </div>
                <div v-else class="text-secondary">geschlossen</div>
              </div>
              <div v-if="branch.openHours.find(el => el.open_day=='SATURDAY')" class="d-flex justify-content-between">Sa: 
                <div class="text-secondary">
                  <div v-for="openHour in branch.openHours.filter(el => el.open_day=='SATURDAY')" :key="JSON.stringify(openHour)">
                    {{ openHour.open_time.substr(0,5) }} - {{ openHour.close_time.substr(0,5) }}
                  </div>
                </div>
              </div>
              <div v-if="branch.openHours.find(el => el.open_day=='SUNDAY')" class="d-flex justify-content-between">Mo: 
                <div class="text-secondary">
                  <div v-for="openHour in branch.openHours.filter(el => el.open_day=='SUNDAY')" :key="JSON.stringify(openHour)">
                    {{ openHour.open_time.substr(0,5) }} - {{ openHour.close_time.substr(0,5) }}
                  </div>
                </div>
              </div>
            <!-- <router-link :to="'branch/'+branch.id" class="btn btn-secondary">Mehr Informationen</!-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Branches",
  computed: {
    ...mapGetters({
      branches: "branches",
      loaded: "branches_loaded"
    }),
    branchesWithPic() {
      return this.branches.filter(branch => branch.picture);
    },
    branchesWithoutPic() {
      return this.branches.filter(branch => !branch.picture);
    }
  }
};
</script>

<style>
</style>

import Vue from 'vue'
import API from '@/plugins/api.js'
Vue.use(API)
let store = new Vue() // use the store var for request api
const module_lesson = {
    state: {
        loaded: null,
        lessons: []
    },
    mutations: {
        lessons_set(state, lessons) {
            state.lessons = lessons;
            state.loaded = true;
        },
        lessons_startup(state) {
            state.loaded = false
        }
    },
    actions: {
        async initLessons(context) {
            if (context.state.loaded!==null) return;
            context.commit('lessons_startup');
                setInterval(() => context.dispatch('updateLessons'), 5*60*1000);
                let offset = 0;
                let code = async () => {
                    if (offset<10) offset++;
                    await context.dispatch('updateLessons')
                        .catch(err => {
                            setTimeout(() => code(), 1000 + offset * 1000);
                            if (!err.includes("Network")) throw err;
                            else console.warn(err);          
                        });
                }
                await code();
        },
        async updateLessons(context) {
                await store.$api.get("lesson")
                    .then(response => {
                        context.commit("lessons_set", response.data.result);
                    });
        },
        async getLesson(context, id) {
            let lesson = context.getters.lessons.find(lesson => lesson.id==id);
            if (lesson) return lesson;
            return store.$api.get(`lesson/${id}`)
                .then(response => response.data.result);
        }
    },
    getters: {
        lessons(state) {
            return state.lessons
        },
        module_lesson_loaded(state) {
            return state.loaded
        },
        lessons_count(state) {
            return state.lessons.length
        }
    }
}
export default module_lesson
import Vue from 'vue'
import API from '@/plugins/api.js'
Vue.use(API)
let store = new Vue() // use the store var for request api
const module_company = {
  state: { 
    loaded: null,
    data: JSON.parse(localStorage.getItem('company')) || null,
  },
  mutations: {
    company_set(state, input) {
      // TODO: Mapping is missing here
      state.data=input
      localStorage.setItem('company', JSON.stringify(input));
      state.loaded=true;
    },
    company_startup(state) {
      state.loaded=false;
    },
  },
  actions: {
    async initCompany(context) {
      if (context.state.loaded!==null) return;
      context.commit('company_startup');
      setInterval(() => context.dispatch('updateCompany'), 5 * 60 * 1000);
      let offset = 0;
      let code = async () => {
        if (offset < 10) offset++;
        await context.dispatch('updateCompany')
          .catch(err => {
            setTimeout(() => code(), 1000 + offset * 1000);
            if (!err.includes("Network")) throw err;
            else console.warn(err);
          });
      }
      await code();
    },
    async updateCompany(context) {
        return await store.$api.get("company")
          .then(response => {
            context.commit("company_set", response.data.result)
            return response.data.result;
          })
          .catch(error => {
            console.error(error)
            throw error;
          })
    },
  },
  getters: {
    company(state) {
      return state.data
    },
    module_company_loaded(state) {
      return (state.data && state.data!==null)
    }
   }
}
export default module_company
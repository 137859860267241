/* eslint-disable */
import Vue from 'vue'
import API from '@/plugins/api.js'
Vue.use(API)
let store = new Vue() // use the store var for request api
const module_course = {
  state: { 
    loaded: null,
    courses: [] 
  },
  mutations: {
    courses_set(state, courses) {
      state.courses=courses;
      state.loaded=true;
    },
    courses_startup(state) {
      state.loaded=false
    }
  },
  actions: {
    async initCourses(context) {
      if (context.state.loaded!==null) return;
      context.commit('courses_startup');
        setInterval(() => context.dispatch('updateCourses'), 5 * 60 * 1000);
        let offset = 0;
        let code = async () => {
          if (offset < 10) offset++;
          await context.dispatch('updateCourses')
            .catch(err => {
              setTimeout(() => code(), 1000 + offset * 1000);
              if (!err.includes("Network")) throw err;
              else console.warn(err);            
            });
        }
        await code();
    },
    async updateCourses(context) {
        return await store.$api.get("course")
          .then(response => {
            context.commit("courses_set", response.data.result)
            return response;
          })
    },
  },
  getters: {
    courses(state) {
      return state.courses
    },
    module_course_loaded(state) {
      return state.loaded
    },
    courses_count(state) {
      return state.courses.length
    },
    getCourse: (state) => (course_id) => {
      if (!(state.courses.find((course) => course.id==course_id))) throw new Error("NOT_FOUND");
      return state.courses.find((course) => course.id==course_id);
    },
    getParentCoursesDirectByLessonId: (state) => (lesson_id) => {
      var output=[];
      // state.courses.forEach((course) => {
      //   if (course.lessons.direct.find((clesson_id) => lesson_id==clesson_id)) {
      //     output = [...output, course];
      //   }
      // })
      return output;
    },
    getParentCoursesIdDirectByLessonId: (state) => (lesson_id) => {
      var output=[];
      // state.courses.forEach((course) => {
      //   if (course.lessons.direct.find((clesson_id) => lesson_id==clesson_id)) {
      //     output = [...output, course.id];
      //   }
      // })
      return output;
    },
    getParentCoursesDirectByUnitId: (state) => (unit_id) => {
      var output=[];
      // state.courses.forEach((course) => {
      //   if (course.units.direct.find((cunit_id) => unit_id==cunit_id)) {
      //     output = [...output, course];
      //   }
      // })
      return output;
    },
    getParentCoursesIdDirectByUnitId: (state) => (unit_id) => {
      var output=[];
      // state.courses.forEach((course) => {
      //   if (course.units.direct.find((cunit_id) => unit_id==cunit_id)) {
      //     output = [...output, course.id];
      //   }
      // })
      return output;
    },
   }
}
export default module_course
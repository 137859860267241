import axios from 'axios'
const API = {
    // The install method is all that needs to exist on the plugin object.
    // It takes the global Vue object as well as user-defined options.
    install(Vue) {
        Vue.prototype.$api = axios.create({
            baseURL: "https://fahrschule-api.de/fahrschule_werner/",
            // baseURL: "http://localhost:3000/fahrschule_werner/",
            responseType: 'json'
        });
    }
};

export default API;

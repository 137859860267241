import Vue from 'vue'
import API from '@/plugins/api.js'
Vue.use(API)
let store = new Vue() // use the store var for request api
const module_unit = {
  state: {
    loaded: null,
    units: [],
    unit_groups: []
  },
  mutations: {
    units_set(state, units) {
      state.units = units
    },
    unit_groups_set(state, unit_groups) {
      state.unit_groups = unit_groups
    },
    units_loaded(state) {
      state.loaded = true
    },
    units_startup(state) {
      state.loaded = false
    }
  },
  actions: {
    async initUnits(context) {
      if (context.state.loaded!==null) return;
      context.commit('units_startup');
        setInterval(() => context.dispatch('updateUnitModule'), 5 * 60 * 1000);
        let offset = 0;
        let code = async () => {
          if (offset < 10) offset++;
          await context.dispatch('updateUnitModule')
            .catch(err => {
              setTimeout(() => code(), 1000 + offset * 1000);
              if (!err.includes("Network")) throw err;
              else console.warn(err);            
            });
        }
        await code();
    },
    updateUnitModule(context) {
      return Promise.all([
        (async () => {
          let response = await store.$api.get("unit")
          context.commit("units_set", response.data.result);
        })(),
        (async () => {
          let response = await store.$api.get("unit/group")
          context.commit("unit_groups_set", response.data.result);
        })()
      ]).then(() => {
        return context.commit("units_loaded")
      }).catch(error => {
        context.commit("units_error")
        throw error;
      });
    },
  },
  getters: {
    units(state) {
      return state.units
    },
    unit_groups(state) {
      return state.unit_groups
    },
    module_unit_loaded(state) {
      return state.loaded
    },
    units_count(state) {
      return state.units.length
    },
    getUnit: (state) => (unit_id) => {
      let unit = state.units.find((unit) => unit.id == unit_id);
      if (!unit) throw new Error("NOT_FOUND");
      return unit;
    },
    getUnitGroup: (state) => (unit_group_id) => {
      let unit_group = state.unit_groups.find((unit_group) => unit_group.id == unit_group_id);
      if (!unit_group) throw new Error("NOT_FOUND");
      return unit_group;
    }
  }
}
export default module_unit
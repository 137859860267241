import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '@/views/Index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    meta: {
      title: 'Fahrschule Werner - Startseite',
    },
    component: Index
  },
  {
    path: '/anmeldung',
    name: 'Anmeldeformular',
    meta: {
      title: 'Fahrschule Werner - Anmeldung',
    },
    component: () => import('../views/Anmeldefomular.vue')
  },
  {
    path: '/chatbot_test09112021',
    name: 'ChatBot',
    meta: {
      title: 'Fahrschule Werner - ChatBot Test',
    },
    component: () => import('../views/ChatBotTest.vue')
  },
  {
    path: '/kontakt',
    name: 'Kontakt',
    meta: {
      title: 'Fahrschule Werner - Kontakt',
    },
    component: () => import('../views/Contact.vue')
  },
  {
    path: '/impressum',
    name: 'Impressum',
    meta: {
      title: 'Fahrschule Werner - Impressum',
    },
    component: () => import('../views/Impressum.vue')
  },
  {
    path: '/theorie',
    name: 'Theorie',
    meta: {
      title: 'Fahrschule Werner - Theorie',
    },
    component: () => import('../views/LessonsDefault.vue')
  },
  {
    path: '/massnahmen',
    name: 'Maßnahmen',
    meta: {
      title: 'Fahrschule Werner - Maßnahmen',
    },
    component: () => import('../views/LessonsMeasure.vue')
  },
  {
    path: '/klassen',
    name: 'Klassen',
    meta: {
      title: 'Fahrschule Werner - Klassen',
    },
    component: () => import('../views/Classes.vue')
  },
  {
    path: '/job',
    name: 'Job',
    meta: {
      title: 'Fahrschule Werner - Fahrlehrer werden',
    },
    component: () => import('../views/Job.vue')
  },
  // {
  //   path: '/review',
  //   name: 'Review',
  //   meta: {
  //     title: 'Fahrschule Werner - Bewertungen',
  //   },
  //   component: () => import('../views/Review.vue')
  // },
  {
    path: '/leitfaden',
    name: 'Guideline',
    meta: {
      title: 'Fahrschule Werner - Leitfaden',
    },
    component: () => import('../views/Guide.vue')
  },
  {
    path: '/index.php',
    name: "old-php",
    redirect: { name: "Index" }
  },
  {
    path: '/*',
    name: '404',
    meta: {
      title: 'Fahrschule Werner - Seite nicht gefunden',
    },
    component: () => import('../views/system/404.vue')
  }
]

const router = new VueRouter({
  routes,
  mode: "history",
  scrollBehavior() {
      return {x: 0, y: 0}
  }
});

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  // const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) document.title = nearestWithTitle.meta.title;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create, so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));

  next();
});

export default router

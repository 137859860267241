<template>
  <div class="container-fluid" id="classes-banner">
    <div class="row my-3 justify-content-center">
      <div class="col-12 col-lg-10 text-center">
        <h3>Führerschein machen! Aber wie?</h3>
      </div>
      <div
        class="col-12 col-lg-10 text-center font-weight-bold my-3"
        @click="$router.push('leitfaden')"
        style="cursor: pointer;"
      >
        <img class="fas fa-arrow-right" /> Sieh dir unseren Leitfaden an
        <img class="fas fa-arrow-left" />
      </div>
      <div class="col-7">
        <hr />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Guideline"
};
</script>

<style>
</style>
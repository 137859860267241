<template>
  <div v-if="loaded || start" id="app">
    <GlobalAlerts />
    <Nav />
    <b-container class="h-100" fluid>
      <b-row class="h-100 align-items-end">
        <b-col class="p-2"></b-col>
        <b-col class="col-12 m-0 p-0">  <!-- Spacer -->
          <div class="clearfix"></div>
          <router-view/>
        </b-col>
        <b-col class="col-12 align-self-end m-0 p-0" >
            <Footer />
        </b-col>
      </b-row>
    </b-container>
  </div>
  <div v-else>
    <b-container class="h-100 mt-5">
      <b-row>
        <b-col cols="6">
          <img class="w-100" src="@/assets/pictures/logo.png" />
        </b-col>
        <b-col class="mt-5">
          Unsere Webseite ist derzeit offline. Wir bitten um Entschuldigung.
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import Nav from "@/components/layout/Nav"
import GlobalAlerts from "@/components/shared/GlobalAlerts"
import Footer from "@/components/layout/Footer"
import store from "./store"
import "./cookieconsent/index"


export default {
  name: "app",
  store: store,
  components: {
    GlobalAlerts,
    Nav,
    Footer, 
  }, 
  data() {
    return {
      start: true,
    };
  },
  methods: {
    async startup() {
      await store.dispatch("startup")
      .then(async () => {
        console.log("API ready...")
        await store.dispatch("fetchcurrentUser");
      })
      .catch((err) => {
        console.log(err)
        console.error("Error while Connecting to API")
        setTimeout(this.startup, 10000)
      })  
    }
  },
  computed: {
    loaded() {
      return this.$store.getters.module_company_loaded;
    }
  },
  async created() {
    console.log("Startup...")
    await this.startup();
    this.start = false;
  }
}

</script>
<style>
html, body, #app {
  height: 100%;
}
/*Online-Registration-Banner*/
.online_registration_button_caption {
  text-transform: uppercase;
}
.online_registration_banner_button {
  -moz-box-shadow: inset 0px 1px 0px 0px #ffffff;
  -webkit-box-shadow: inset 0px 1px 0px 0px #ffffff;
  box-shadow: inset 0px 1px 0px 0px #ffffff;
  background-color: #ffffff;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  border: 1px solid #dcdcdc;
  display: inline-block;
  cursor: pointer;
  color: #666666;
  font-family: Arial;
  font-weight: bold;
  padding: 6px 24px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #ffffff;
}
.online_registration_banner_button:hover {
  background-color: #f6f6f6;
  text-decoration: none;
  text-underline: none;
  color: #666666;
}
.online_registration_banner_button:active {
  position: relative;
  text-decoration: none;
  text-underline: none;
}

.company-color1 {
  color: #ff00ff;
}

</style>

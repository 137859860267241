import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'

import './plugins/bootstrap-vue'
import App from './App.vue'

Vue.config.productionTip = false

import API from "@/plugins/api.js"
Vue.use(API)

import VueRouter from 'vue-router'
import router from './router'
import store from './store'
Vue.use(VueRouter)

import VueGtag from "vue-gtag";
if (process.env.NODE_ENV == "production") {
  if (process.env.VUE_APP_GA_KEY) {
    Vue.use(VueGtag, {
      config: { id: process.env.VUE_APP_GA_KEY }
    });
  } else console.warn("MISSING ENVIRONMENT VARIABLE: VUE_APP_GA_KEY");
}
  
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
if (process.env.NODE_ENV == "production")
if (process.env.VUE_APP_SENTRY_URL) {
  Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_URL,
    integrations: [new VueIntegration({ Vue, attachProps: true })],
    release: `${process.env.npm_package_name}@${process.env.npm_package_version}`,
    environment: process.env.NODE_ENV
  });
} else console.warn("MISSING ENVIRONMENT VARIABLE: VUE_APP_SENTRY_URL");

// Moment.js
const moment = require('moment')
require('moment/locale/de')

Vue.use(require('vue-moment'), {
  moment
});
new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')

console.log("Service started...");

<template>
  <div class="footer">
    <div class="container-fluid footer-background pt-2">
      <div class="row d-flex justify-content-center pb-3 pt-4">
        <!-- Logo -->
        <div style="cursor: pointer;">
            <img id="tooltip-target-to-top" src="@/assets/pictures/logo.png" alt="Logo" style="width: 14em;" @click="scrollToTop()" />
            <b-tooltip target="tooltip-target-to-top" triggers="hover" placement="bottom">
              Nach oben!
            </b-tooltip>
            <div class="text-light pl-5 pt-3 font-weight-bold d-none d-sm-block" style="position: absolute; top: 2rem; right: 10%;">Ratzelstr.: 0341 / 4299991<br />Lütznerstr.: 0341 / 4771111</div>
        </div>
      </div>
      <hr class="bg-white mx-5 mx-sm-5" />
      <div class="row text-center d-flex justify-content-center">
        <!-- Links -->
        <div class="col-xs-12 col-lg-3 my-3 mx-3">
          <h6 class="text-uppercase mb-4 font-weight-bold text-red footer-caption">Fahren lernen</h6>
          <div class="footer-links">
            <p>
              <router-link to="/klassen">Fahrzeugklassen</router-link>
            </p>
            <p>
              <router-link to="/theorie">Theoriezeiten</router-link>
            </p>
            <p>
              <router-link to="/massnahmen">Maßnahmen</router-link>
            </p>
            <!-- <p>
              <router-link to="/asffes">ASF/FES</router-link>
            </p> -->
          </div>
        </div>
        <div class="col-xs-12 col-lg-3 my-3 mx-3">
          <h6 class="text-uppercase mb-4 font-weight-bold text-red footer-caption">Informieren</h6>
          <div class="footer-links">
            <p>
              <router-link to="/">Startseite</router-link>
            </p>
            <!-- <p>
              <router-link to="/about">Über Uns</router-link>
            </p>
            <p>
						<router-link to="/branches">Unsere Filialen</router-link>
            </p>-->
            <!-- <p>
						<router-link to="/customers">Kundenmeinungen</router-link>
            </p>-->
            <p>
						<router-link to="/jobs">Jobs</router-link>
            </p>
            <p>
              <router-link to="/leitfaden">Leitfaden zum Führerschein</router-link>
            </p>
            <p>
              <router-link to="/corona">Informationen zu Corona<br>Covid-19</router-link>
            </p>
          </div>
        </div>
        <div class="col-xs-12 col-lg-3 my-3 mx-3">
          <h6 class="text-uppercase mb-4 font-weight-bold text-red footer-caption">Mehr</h6>
          <div class="footer-links">
            <p>
              <router-link to="/kontakt">Kontakt</router-link>
            </p>
            <p>
              <router-link to="/impressum">Impressum & Datenschutz</router-link>
            </p>
            <p>
              <a href="AGB.pdf">AGB</a>
            </p>
          </div>
        </div>
      </div>
      <div class="clearfix"></div>
    </div>
    <div class="row footer-background-copyright text-white-50 m-0 p-0">
      <!-- Copyright -->
      <div class="col col-md-8 m-0 p-0">
        <!-- Copyright-Text-->
        <div class="footer-copyright py-3 mx-3 d-flex flex-wrap align-content-start">
          <span class="px-1">Copyright © 2009-{{(new Date()).getFullYear()}}</span>
          <span class="px-1 d-none d-sm-inline">
            <router-link class="footer-link" to="/kontakt">Fahrschule Werner</router-link>
          </span>
          <span class="px-1 d-none d-lg-inline">Alle Rechte vorbehalten.</span>
        </div>
      </div>
      <div class="col-md-4 d-none d-md-block">
        <!-- Company Name -->
        <div class="py-3 mx-3 float-right m-0 p-0">
          <small>
            Webdesign by
            <a class="text-muted" target="_blank" href="https://wer-ner.de">Paul Werner</a>
          </small>
        </div>
      </div>
      <div class="col-12 my-3 d-block d-md-none"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  methods: {
    scrollToTop() {
      window.scrollTo({ top:0, left: 0, behavior: 'smooth' });
    }
  }
};
</script>

<style>
.footer {
  width: 100%;
  background-color: #e0e0e0;
  opacity: 1;
  border-radius: 1px;
  border-color: black;
}
.footer-caption {
  color: #ff00ff;
}
.footer-link:link,
.footer-link:visited,
.footer-link:hover,
.footer-link:active,
.footer-links p a:link,
.footer-links p a:visited,
.footer-links p a:hover,
.footer-links p a:active {
  color: #fce4ec;
}
.footer-background {
  background-color: #2e2e2e;
}
.footer-background-copyright {
  background-color: #212121;
}
</style>

<template>
  <div class="vision container-fluid px-4 py-3" :style="{'background-image': 'url('+background+')'}" style="background-color: #2e2e2e; background-position: center; background-size: cover;">
	<div class="row justify-content-center footer-caption" > 
		<div class="col-12 col-md-10 text-center pb-2" style="background-color: rgba(0,0,0,0.5);">
			<div class="h1 text-warning">Seit vielen Jahren erfolgreich</div>
			<div class="text-white">
				Unser Ziel ist, dass du nicht nur die Prüfung bestehst, sondern selbst <strong>schwierige Situationen meistern</strong> kannst.<br>
				Das wissen auch unsere Fahrlehrer und werden alles daran setzen, damit du dich <strong>sicher fühlen</strong> und <strong>sicher fahren</strong> kannst.<br><br>
				Der Erfolg unserer freundlichen und geduldigen Ausbildungsweise spiegelt sich in der Anzahl der bestandenen Prüfungen wieder.<br>
				<strong>Darauf sind wir stolz.</strong><br>
				<br>
				Falls du doch etwas nicht verstanden haben solltest, dann sprich uns einfach an.<br> 
				<strong>Dafür sind wir da!</strong><br>
				<br>
				Den Theorieunterricht gestalten wir mit Medieneinsatz und Disskusionen so, <br class="d-none d-lg-block">dass er nicht nur <strong>einprägsam</strong> und <strong>informativ</strong> ist, sondern auch <strong>Spaß</strong> macht.
			</div>
		</div>
	</div>
</div>
</template>

<script>
import Image from "@/assets/pictures/lmedia/fahrschule-juergen-mueller-bogen-loham-bild-02-0x0.jpg"
export default {
  name: "vision",
  data() {
      return {
      background: Image
      }
  }
};
</script>

<style>

</style>

<template>
    <div class="container-fluid">
        <div v-if="!api_connection" class="row">
            Aktuell sind nicht alle unserer Services verfügbar<br>
            <small>Wir versuchen diese so schnell wie möglich wiederherzustellen</small>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    data() {
        return {
        }
    },
  computed: {
      ...mapGetters({
      api_connection: 'loaded' 
    })
  }
}
</script>

import Vue from 'vue'
import Vuex from 'vuex'
import API from '@/plugins/api.js'
import module_branch from "./module_branch"
import module_company from "./module_company"
import module_course from "./module_course"
import module_unit from "./module_unit"
import module_lesson from "./module_lesson"
Vue.use(Vuex)
Vue.use(API)
let store = new Vue() // use the store var for request api
// Actions, Mutations and and getters of submodules have to expose like: <modulename>_<othername>


export default new Vuex.Store({
  state: {
    loadingStatus: 'notloaded',
    currentUser: null,
    token: localStorage.getItem('access_token') || null,
  },
  getters: {
    currentUser(state) {
      return state.currentUser
    },
    loaded(state) {
      return state.loadingStatus.toLowerCase()=='ok';
    },
    connectionError(state) {
      return state.loadingStatus.toLowerCase()=="connection error"
    },
    loggedIn(state) {
      return state.token !== null
    },
  },
  mutations: {
    // Syncronys
    setLoadingStatus(state, status) {
      state.loadingStatus = status;
    },
    setUser(state, user) {
      state.currentUser = user;
    },
    unsetUser(state) {
      state.currentUser = null;
    },
    retrieveToken(state, token) {
      state.token = token
    },
    destroyToken(state) {
      state.token = null
    },
  },
  actions: {
    // asyncronys or syncronys
    async startup(context) {
        await store.$api.get('/')
        .catch((error) => {
          context.commit("setLoadingStatus", "Connection Error");
          throw error;
        });
        context.commit("setLoadingStatus", 'OK');
        await Promise.all([
          context.dispatch('initBranches'),
          context.dispatch('initCompany'),
        ])
        return;
    },
    fetchcurrentUser(context) {
      return new Promise((resolve, reject) => {
        store.$api.get('').then(response => {
          context.commit("setUser", response.data.user)
          resolve(response)
        }).catch(error => {
          console.error(error)
          reject(error)
        })
      })
    },
    register(context, data) {
      return new Promise((resolve, reject) => {
        this.$api.post('/register', {
          username: data.username,
          firstname: data.firstname,
          lastname: data.lastname,
          email: data.email,
          password: data.password.sha512(),
        })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    logout(context) {
      // store.$api.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          this.$api.post('/logout')
            .then(response => {
              // localStorage.removeItem('access_token')
              // context.commit('destroyToken')
              context.commit("unsetUser")
              resolve(response)
            })
            .catch(error => {
              // localStorage.removeItem('access_token')
              // context.commit('destroyToken')
              context.commit("unsetUser")
              reject(error)
            })
        })
      }
    },
    login(context, credentials) {
      store.$api.get(
        'system/login?user=1001&password=ab8526d67ecf6dfd0de3364eed4cabdf5f16169dc25fedc740de967e2bbbb1c5d5688cdce558e88d353fd5d557256776c13678a52fe9dd7037be4835d7bf5c15'
      )
        .then(response => {
          console.log(response)
        }).catch(error => {
          console.error(error)
        })
      return new Promise((resolve, reject) => {
        store.$api.post('/login', {
          username: credentials.username,
          password: credentials.password.sha512(),
        })
          .then(response => {
            // const token = response.data.access_token
            // localStorage.setItem('access_token', token)
            // context.commit('retrieveToken', token)
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
        })
    },
    async initTheory(context) {
      await Promise.all([
       context.dispatch('initCourses'),
       context.dispatch('initUnits'),
       context.dispatch('initLessons'),
    ]);
    }
  },
  modules: {
    branch: module_branch,
    company: module_company,
    course: module_course,
    unit: module_unit,
    lesson: module_lesson,
  }
})

